
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';
import { isEmpty } from '@/utils/misc';
import { BendSpeedFlowStates, LASER_GANTT_STATES } from '@/models/Charts/chartsData';
import ZoomSelector from '@/components/tableFilters/ZoomSelector.vue';
import StateFilter from '@/components/tableFilters/StateFilter.vue';
import { getStateColour } from '@/utils/color';

@Component({
  components: {
    CustomTableFilters,
    ZoomSelector,
    StateFilter,
  },
  computed: {
    BendSpeedFlowStates: () => BendSpeedFlowStates,
  },
  methods: {
    isEmpty,
    getStateColour,
  },
})
export default class LaserGanttChartFilters extends Vue {
  @Prop({ required: true })
  private defaultZoom!: [Date, Date];

  private static readonly DEFAULT_SLIDER_START_VALUE = 0;

  private selectedStates: string[] = [];
  private zoomValue: [number, number] = [
    LaserGanttChartFilters.DEFAULT_SLIDER_START_VALUE,
    LaserGanttChartFilters.DEFAULT_SLIDER_START_VALUE,
  ];

  private startTimestamp: moment.Moment = moment.utc().subtract(24, 'hours');

  private mounted() {
    this.selectedStates = this.states;
    this.initializeTimestamps();
    this.initializeValue();
  }

  private get states() {
    return LASER_GANTT_STATES;
  }

  @Watch('defaultZoom')
  private watchDefaultZoom() {
    this.initializeTimestamps();
    this.initializeValue();

    // The following code handles the case when the table is refreshed (and thus the defaultZoom changed)
    // and we want to update the filter state.
    // If only the zoom was being used, and now that the defaultZoom was reset,
    // no filter is being used and the table must be notified (by sending areApplying to false)
    const areApplying = this.selectedStates.length !== this.states.length;
    this.$emit('filters-change', this.selectedStates, this.defaultZoom, areApplying);
  }

  private initializeTimestamps() {
    this.startTimestamp = moment(this.defaultZoom[0]);
  }

  private initializeValue() {
    this.zoomValue = [LaserGanttChartFilters.DEFAULT_SLIDER_START_VALUE, this.totalMinutes];
  }

  private get totalMinutes(): number {
    return moment(this.defaultZoom[1]).diff(this.defaultZoom[0], 'minutes');
  }

  private emitFilters() {
    const zoomValues = [
      moment(this.startTimestamp).add(this.zoomValue[0], 'minutes').toDate(),
      moment(this.startTimestamp).add(this.zoomValue[1], 'minutes').toDate(),
    ];
    const areApplying = !(
      this.selectedStates.length === this.states.length &&
      this.zoomValue[0] === LaserGanttChartFilters.DEFAULT_SLIDER_START_VALUE &&
      this.zoomValue[1] === this.totalMinutes
    );
    this.$emit('filters-change', this.selectedStates, zoomValues, areApplying);
  }

  private clearAllFilters() {
    this.selectedStates = this.states;
    this.zoomValue = [LaserGanttChartFilters.DEFAULT_SLIDER_START_VALUE, this.totalMinutes];
    this.emitFilters();
  }
}
